import React from 'react';
import { Logo, VerticalSeparator, VerticalSeparatorWithGradient } from './assets';

import './App.css';

function App() {
  return (
      <div className="App">
          <header id="header" className="App-header">
              <a href="#" className="App-logo">
                  <Logo />
              </a>
              <VerticalSeparator />
              <nav className="App-navigation">
                  <a href="#research" className="App-link">Исследования</a>
                  <a href="#buyers" className="App-link">Покупатели</a>
                  <a href="#experience" className="App-link">Опыт покупки</a>
                  <a href="#brands" className="App-link">Бренды</a>
                  <a href="#prices" className="App-link">Цены</a>
              </nav>
          </header>
          <main className="App-main">
              <section className="Research-section">
                  <h1 className="Research-title">Исследуем рынок  и помогаем вашему бренду расти</h1>
                  <h4 className="Research-subtitle">AI помощник для поиска точек роста вашего продукта и увеличение продаж и прибыли</h4>
                  <button className="Button">Демо-версия</button>
                  <div className="Research-image" />
              </section>
              <section id="research" className="Marketplace-section">
                  <VerticalSeparatorWithGradient className="App-separator" />
                  <div className="Tab">Исследуем рынок на маркетплейсах</div>
                  <span className="Marketplace-text">
                      Проводим исследования для вашего бренда на<br/>
                      <strong>Lamoda, OZON, Wildberries и Яндекс Маркет</strong>
                  </span>
                  <div className="Marketplace-content">
                      <div className="Marketplace-content-section">
                          <div className="Marketplace-content-column">
                              <div className="Marketplace-text-section">
                                  <h3 className="Marketplace-content-title">
                                      Цены в заданной категории
                                  </h3>
                                  <span className="Marketplace-content-subtitle">
                                      Определение вашего ценового сегмента, ценовое позиционирование для вашего бренда, поиск лучших ценовых уровней для выбранных товарных групп
                                  </span>
                              </div>
                              <button className="Button">Демо-версия</button>
                          </div>
                          <div className="Marketplace-content-photo Price-categories-photo" />
                      </div>
                      <div className="Marketplace-content-section">
                          <div className="Marketplace-content-photo Brands-photo" />
                          <div className="Marketplace-content-column">
                              <div className="Marketplace-text-section">
                                  <h3 className="Marketplace-content-title">
                                      Бренды
                                  </h3>
                                  <span className="Marketplace-content-subtitle">
                                      Определение вашего ценового сегмента, поиск релевантных брендов, сравнение по показателям и ценовым уровням, посадке, цвету и другим атрибутам. Лучшие товары конкурентов по динамике продаж. Определение вашей целевой ниши среди брендов-конкурентов
                                  </span>
                              </div>
                              <button className="Button">Демо-версия</button>
                          </div>
                      </div>
                      <div className="Marketplace-content-section">
                          <div className="Marketplace-content-column">
                              <div className="Marketplace-text-section">
                                  <h3 className="Marketplace-content-title">
                                      Категории на маркетплейсе
                                  </h3>
                                  <span className="Marketplace-content-subtitle">
                                      Понимание объема рынка в выбранной категории в деньгах и штуках продаж, динамика цен внутри категории, тренды спроса в деньгах и статистика поиска, перспективные направления для развития, лучшие бренды.
                                  </span>
                              </div>
                              <button className="Button">Демо-версия</button>
                          </div>
                          <div className="Marketplace-content-photo Marketplace-categories-photo" />
                      </div>
                      <div className="Marketplace-content-section">
                          <div className="Marketplace-content-photo Growth-photo" />
                          <div className="Marketplace-content-column">
                              <div className="Marketplace-text-section">
                                  <h3 className="Marketplace-content-title">
                                      Поиск ниш для роста
                                  </h3>
                                  <span className="Marketplace-content-subtitle">
                                      Автоматический анализ, что общего в новых продуктах конкурентов - показ похожих моделей, которые встретились у более чем х% конкурентов. Лучшие артикулы по продажам в категории, все харакатеристики лучших артикулов, аналика отзывов (плюсы  и минусы)
                                  </span>
                              </div>
                              <button className="Button">Демо-версия</button>
                          </div>
                      </div>
                  </div>
              </section>
          </main>
      </div>
  );
}

export default App;
